body {
    background: #EDECEC;
    font-family: "Open Sans", sans-serif;
    font-size: 10pt;
    margin: 0;
    padding: 0;
}

label {
    color: #B0B0B0;
}

input {
    background-color: #272727 !important;
    color: #C0C0C0 !important;
    border-color: #A0A0A0 !important;
}

a, a:hover, a:focus, a:active {
    color: #E0E0FF;
    padding: 10px;
}

.button-icon-text{
    padding-left: 5px;
}

#loader {
    background: #000;
    z-index: 999;
    text-align: center;

    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
}

#loader img {
    margin: 0 auto;
    position: relative;
    top: 45%;
}

#page-content-wrapper {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;

    /*
    margin: 0;
    padding: 0;
    */
}

#page-content-wrapper:hover {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.container-fluid .row,
.container-fluid .row .col-lg-12 {
    height: 100% !important;
    padding: 0;
}

.row {
    margin: 0;
}

.container {
    height: 100% !important;
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.form-container {
    min-height: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.7);
}

#page-content-wrapper.wallpapermode {
    opacity: 0;
}

.nomargin {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.row.divider {
    border-top: 1px solid rgba(240, 240, 240, 0.5);
    margin-top: 10px;
    padding-top: 10px;
}

.transition {
    -webkit-transition: all 5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.transition5s {
    -webkit-transition: all 5s ease;
    -moz-transition: all 5s ease;
    -o-transition: all 5s ease;
    transition: all 5s ease;
}


.login_background {
    background: transparent no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.panel {
    border: 0 !important;
    background-color: transparent; !important;
    padding: 0 20px;

    /*
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;

    background-color: rgba(255, 255, 255, 0.7) !important;
    border-color: rgba(230, 230, 230, 0.7) -moz-use-text-color -moz-use-text-color !important;

    border-image: none;
    border-radius: 5px;
    border-style: solid none none;
    border-width: 2px 0 0;

    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);

    float: left;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    */
}

.panel .panel-body {
    /*
    float: left;
    width: 100%;
    margin: 0;
    padding: 40px;

    -webkit-box-shadow:inset 0 0 100px 0 rgba(0,0,0,0.3);
    box-shadow:inset 0 0 100px 0 rgba(0,0,0,0.3);
    */
}

.fa-btn {
    margin-right: 10px;
}

.logo {
    text-align: center;
}

.logo img {
    margin: 0 auto 30px auto;
}

.logo p {
    margin: 0 auto 50px;
    color: #FFFFFF;
    text-shadow: 0 1px 1px #000000;
    font-size: 10pt;
}

.logo sup {
    font: 400 30px/1 'Source Sans Pro', Arial, sans-serif;
    top: -10px;
    right: 0;
}

.lang-selector {
    text-align: center;
    width: 100%;
}
.lang-selector a {
    margin: 10px;
    display: inline-block;
}


.form-control {
    /*background-color: rgba(255, 255, 255, 0.7) !important;*/
}

input.form-control {
    opacity: 0.6;
    color: #000000;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

input.form-control:focus,
input.form-control:hover {
    opacity: 1;
}

h1 {
    font: 900 80px/1 'Source Sans Pro', Arial, sans-serif;
    color: #fff;
    text-align: center;
    letter-spacing: -3px;
    margin: 40px auto 20px auto;
    padding: 0;
    width: 100%;

    text-shadow:     0 1px 0 hsl(174,5%,80%),
                     0 2px 0 hsl(174,5%,75%),
                     0 3px 0 hsl(174,5%,70%),
                     0 4px 0 hsl(174,5%,66%),
                     0 5px 0 hsl(174,5%,64%),
                     0 6px 0 hsl(174,5%,62%),
                     0 7px 0 hsl(174,5%,61%),
                     0 8px 0 hsl(174,5%,60%),

                     0 0 5px rgba(0,0,0,.05),
                    0 1px 3px rgba(0,0,0,.2),
                    0 3px 5px rgba(0,0,0,.2),
                   0 5px 10px rgba(0,0,0,.2),
                  0 10px 10px rgba(0,0,0,.2),
                  0 20px 20px rgba(0,0,0,.3);
}

h3 {
    text-align: center;
    margin: -15px auto 20px auto !important;
    padding: 0 !important;
    color: #404040;
    text-shadow: 0 1px 1px #FFFFFF;
}

.copyright {
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0 1px 1px #000000;
    padding-bottom: 15px;
}
/*.copyright a img {
    margin-left: 0;
}*/

img.login-copyright {
    height: 21px;
    margin-top: -12px;
    margin-left: -5px;
}

#wait-login {
    position: absolute;
    top: 48%;
    left: 0;
    width: 100%;
    padding: 15px 0;
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    display: none;
}

@media(min-width: 481px) {
    .checkbox {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

@media(max-width: 991px) {
    .checkbox {
        margin-top: 5px;
        margin-bottom: 20px;
    }
}

@media(max-width: 480px) {
    h1 {
        font: 900 60px/1 'Source Sans Pro', Arial, sans-serif;
    }

    .logo p {
        font-size: 9pt;
    }

    .container-fluid {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        overflow-x: hidden;

        /*
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        */
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;

    }


    .container {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .panel {
        border-radius: 0 !important;
    }

    .panel .panel-body {
        padding: 30px 15px !important;
    }

}
